import React from 'react';
import { findTarget } from '../../../utils/findTarget';
import { Button } from '@material-ui/core';
import styles from './button2.module.scss';

interface IButton2Interface {
    label: string;
    redirect: string;
    classname?: string;
    rel?: string;
    target?: string;
}

const Button2 = ({
    label,
    redirect,
    classname,
    rel,
    target,
}: IButton2Interface) => {
    return (
        <Button
            component="a"
            size="small"
            href={redirect}
            className={styles.a_button2 + ` ${classname}`}
            rel={
                rel
                    ? rel
                    : findTarget(redirect) === '_blank'
                    ? 'noreferrer follow'
                    : 'referrer follow'
            }
            target={target ? target : findTarget(redirect)}
        >
            {label}
        </Button>
    );
};

export default Button2;
